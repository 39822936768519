<template>
  <b-img class="logo" style="height: 30px" :src="appLogoImage" fluid />
</template>

<script>
import { BImg } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BImg,
  },
  setup() {
    // App Name
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
};
</script>